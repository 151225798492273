import { TransitionProps, ITransition } from 'vev';
import React from 'react';
import anime, { AnimeInstance, AnimeParams, AnimeTimelineInstance } from 'animejs';
import { isString, isUndefined } from '../../utils';
import { simpleAnime } from '../../utils/animation';

type TransitionStatus = 'unmounted' | 'exited' | 'entering' | 'entered' | 'exiting' | null;

function createAnimation(
  tween: ITransition = 'fade',
  target: HTMLElement,
  reverse?: boolean
): AnimeInstance {
  if (isString(tween)) tween = simpleAnime(tween, reverse);
  if (!tween.targets) tween.targets = target;
  if (isUndefined(tween.easing)) tween.easing = 'easeOutSine';
  if (isUndefined(tween.duration)) tween.duration = 500;

  return anime(tween as AnimeParams);
}

export default function Transition({
  children,
  show = true,
  enter,
  exit,
  loop,
  onEnter,
  onEntered,
  onExit,
  onExited,
  noUnmount,
  ...rest
}: TransitionProps) {
  const ref = React.useRef<HTMLElement>(null);
  const [status, setStatus] = React.useState<TransitionStatus>(null);

  const unmounted = !noUnmount && !show && (!status || status === 'exited');

  React.useLayoutEffect(() => {
    let animation: AnimeInstance | AnimeTimelineInstance;
    const node = ref.current;

    if (unmounted || !node) return;

    if (show) {
      animation = (onEnter && onEnter(node)) || createAnimation(enter, node);
      animation.complete = () => {
        onEntered && onEntered(node);
        setStatus('entered');
      };

      setStatus('entering');
    } else {
      animation = (onExit && onExit(node)) || createAnimation(exit, node, true);
      animation.complete = () => {
        onExited && onExited(node);
        setStatus('exited');
      };
      if (!status) animation.pause();
      else setStatus('exiting');
    }

    return () => animation.pause();
  }, [show]);

  React.useLayoutEffect(() => {
    if (status === 'entered' && loop) {
      const tween: AnimeParams = { targets: ref.current, ...loop };
      if (!tween.loop) tween.loop = true;
      if (isUndefined(tween.easing)) tween.easing = 'easeInOutSine';
      if (isUndefined(tween.duration)) tween.duration = 400;
      if (isUndefined(tween.direction)) tween.direction = 'alternate';
      const res = anime(tween);
      return () => res.pause();
    }
  }, [status]);

  if (unmounted) return null;

  (rest as any).ref = ref;
  const child = React.Children.only(children);
  if (!child || !React.isValidElement(child)) {
    throw new Error('Transition must have child of type element');
  }

  return React.cloneElement(child, rest);
}
