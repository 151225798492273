import anime from 'animejs';

const DEFAULT_TIME = 1;
export const EASING_FUNCS = [
  'linear',
  'easeInQuad',
  'easeOutQuad',
  'easeInOutQuad',
  'easeInElastic()',
  'easeOutElastic()',
  'easeInOutElastic()',
  'spring()',
  'spring()',
  'spring()',
  'easeInBack',
  'easeOutBack',
  'easeInOutBack'
];

export type ITweenAttr = [number, number, number, number];
export type AnimeTarget = string | object | HTMLElement | SVGElement | NodeList | null;
export interface ITween {
  time?: number;
  x?: ITweenAttr;
  y?: ITweenAttr;
  opacity?: ITweenAttr;
  scale?: ITweenAttr;
}

export interface ITweenValues {
  x?: number;
  y?: number;
  opacity?: number;
  scale?: number;
}

export default class Timeline {
  private scale: { [attr: string]: number } = {};
  private replace: { [attr: string]: string } = {
    x: 'translateX',
    y: 'translateY'
  };
  private format: { [attr: string]: (value: any) => any } = {};

  instance: anime.AnimeTimelineInstance;

  constructor(onComplete: () => any, onStart?: () => any) {
    this.instance = anime.timeline({
      autoplay: false,
      complete: onComplete,
      begin: onStart
    });
  }

  play() {
    this.instance.play();
  }

  reverse() {
    this.instance.reverse();
    this.instance.completed = false;
    this.instance.play();
  }

  reset() {
    this.instance = anime.timeline({
      autoplay: false,
      complete: this.instance.complete,
      begin: this.instance.begin
    });
    this.instance.pause();
  }

  scaling(scale: {}): void {
    this.scale = scale;
  }

  addReplace<T extends keyof ITween>(attr: T, replaceAttr: string) {
    this.replace[attr] = replaceAttr;
  }

  addFormat<T extends keyof ITween>(attr: T, formatFunc: (value: any) => any) {
    this.format[attr] = formatFunc;
  }

  toValues(targets: AnimeTarget, tweens: ITween[] | ITween, toValues: ITweenValues) {
    if (!tweens || !targets) return;
    if (!Array.isArray(tweens)) tweens = [tweens];

    const { scale } = this;
    // Cloning to values to not screw them up when writing to the object
    toValues = { ...toValues };
    // Calculate the total time for all the tweens, because we need to build the tween backwards becuase the end values is known
    let currentTime = tweens.reduce((value, tween) => value + (tween.time || 1), 0);
    let i = tweens.length;

    // Loop over the tweens backwards where the first tweens is set to the to values
    while (i--) {
      let tween = tweens[i];
      const time = tween.time || DEFAULT_TIME;
      currentTime -= time;
      for (let attr in tween) {
        if (attr === 'time') continue;

        let [value, easing, startOffset, endOffset] = tween[attr];
        // if (scale[attr]) value *= scale[attr];

        this.addTween(
          targets,
          attr as keyof ITween,
          value,
          toValues[attr],
          easing,
          startOffset * time + currentTime,
          endOffset * time + currentTime
        );

        toValues[attr] = value;
      }
    }
  }

  fromValues(target: AnimeTarget, tweens: ITween[] | ITween, fromValues: ITweenValues) {
    if (!tweens || !target) return;
    if (!(tweens instanceof Array)) tweens = [tweens];

    fromValues = { ...fromValues };

    var currentTime = 0;
    for (let tween of tweens) {
      const time = tween.time || DEFAULT_TIME;
      for (let attr in tween) {
        if (attr === 'time') continue;

        let [value, easing, startOffset, endOffset] = tween[attr];

        this.addTween(
          target,
          attr as keyof ITween,
          fromValues[attr],
          value,
          easing,
          startOffset * time + currentTime,
          endOffset * time + currentTime
        );

        fromValues[attr] = value;
      }

      currentTime += time;
    }
  }

  private addTween<K extends keyof ITween>(
    targets: AnimeTarget,
    attr: K,
    fromValue: number,
    toValue: number,
    easing: number | string,
    startTime: number,
    endTime: number
  ) {
    if (this.format[attr]) {
      fromValue = this.format[attr](fromValue);
      toValue = this.format[attr](toValue);
    }

    if (attr in this.scale) {
      fromValue *= this.scale[attr];
      toValue *= this.scale[attr];
    }

    // @ts-ignore
    this.instance.add(
      {
        targets,
        delay: startTime * 1000,
        duration: (endTime - startTime) * 1000,
        easing: typeof easing === 'string' ? easing : EASING_FUNCS[easing],
        [this.replace[attr] || attr]: [fromValue, toValue]
      },
      0
    );
  }
}
