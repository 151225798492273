import { IContent, IAppState } from 'vev';
import { root, global } from '../utils/dom';
import { isFunction } from '../utils/type';
import system from '../system';

const AppState: IAppState = {
  scrollTop: 0,
  device: 'desktop',
  viewport: {
    width: global.innerWidth,
    height: global.innerHeight,
    scrollHeight: root.scrollHeight
  },
  images: {},
  shapes: {},
  models: [],
  pages: [],
  pkg: {},
  editorState: {},
  widgets: {}
};

export function getModel(modelKey: string): IContent | undefined {
  for (let model of AppState.models) if (model.key === modelKey) return model;
  return undefined;
}

export function getModelChildren(modelKey: string): string[] {
  const model = getModel(modelKey);
  return (model && model.children) || [];
}

export function importAllWidgets(): Promise<any> {
  let types = {};
  for (const m of AppState.models) {
    if (m.type && !types[m.type]) types[m.type] = m.type;
  }

  Object.assign(types, AppState.pkg);
  return Promise.all(
    Object.keys(types).map(async widgetId => {
      const id = types[widgetId];
      const isPkg = id !== widgetId;
      try {
        const r = await system.import(id, true);
        if (isPkg) {
          AppState.widgets[widgetId] = { default: r[widgetId], pkg: id };
        } else if (r[widgetId]) {
          // Deleted widgets from package
          AppState.widgets[widgetId] = { default: r[widgetId], pkg: id };
        } else {
          // Legacy widgets
          AppState.widgets[widgetId] = r;
        }
      } catch (e) {
        console.error('Failed to load widget', widgetId, e);
      }
    })
  );
}

export function hydratePreRenderedModels(findInside: Element) {
  const preRendered = findInside.getElementsByClassName('__pre');
  for (let i = 0; i < preRendered.length; i++) {
    const model = getModel(preRendered.item(i).id);
    if (model) model.html = true;
  }
}

const listeners: { [event: string]: ((payload: any) => any)[] } = {};

function store<T extends keyof IAppState>(
  watchState: T,
  cb: (payload: IAppState[T]) => void,
  options?: any
): () => void;
function store<T extends keyof IAppState>(emitState: T, payload: any): void;
function store<T extends keyof IAppState>(
  event: T,
  cbOrPayload: any,
  options?: any
): void | (() => void) {
  const list = listeners[event] || (listeners[event] = []);
  if (isFunction(cbOrPayload)) {
    list.push(cbOrPayload);
    return () => {
      const index = list.indexOf(cbOrPayload);
      if (index !== -1) list.splice(index, 1);
    };
  } else {
    AppState[event] = cbOrPayload;
    for (let cb of list) cb(cbOrPayload);
  }
}
export { store };
export default AppState;
