import {
  IObserverOptions,
  VevContent,
  IContent,
  IMenu,
  IEditorState,
  IShape,
  ImageModel,
  IAppState,
  IVisibleOptions,
  IEmojiIcon,
  IVevImage
} from 'vev';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  createContext
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import View from '../manager/view';
import System from '../system';
import AppState, { store } from './state';
import { Intersection, isString } from '../utils';
import { raf } from '../utils/animation';
import widgetPlaceHolder from '../components/placeholder-widget';

const ModelContext = createContext<IContent>({ key: '' });
export const ModelProvider = ModelContext.Provider;

export function useWidget(type: string): null | typeof React.Component {
  const mod = type && AppState.widgets[type];
  const [widget, setWidget] = useState(() => {
    return (mod && mod.default) || widgetPlaceHolder;
  });

  useEffect(() => {
    if (!type) return;
    const pkg = AppState.widgets[type] && AppState.widgets[type].pkg; // pkg can be undefined, legacy..
    const cb = (w) => {
      if (w && w.default && (!widget || widget.default !== widget)) setWidget(() => w.default);
    };
    System.watch(type, cb, pkg);
    () => System.unwatch(type, cb);
  }, [type]);

  if (widget) widget.displayName = type;
  return widget;
}

export function useIntersection<T extends Element>(
  ref: React.RefObject<T>,
  options?: IObserverOptions
): false | IntersectionObserverEntry {
  const [visible, setVisible] = useState<IntersectionObserverEntry | false>(false);
  useEffect(() => {
    const current = ref.current;
    if (current) {
      Intersection.add(current, (e) => setVisible(e), options);
      return () => Intersection.remove(current);
    }
  }, [ref.current, options && options.offsetBottom, options && options.offsetTop]);

  return visible;
}

export function useVisible<T extends Element>(
  ref: React.RefObject<T>,
  options?: IVisibleOptions
): boolean {
  const intersection = useIntersection(ref, options);
  return intersection && intersection.isIntersecting;
}

export function useInterval(callback: () => any, delay: number) {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useHover(): [
  boolean,
  {
    onMouseEnter: (e: React.MouseEvent) => void;
    onMouseLeave: (e: React.MouseEvent) => void;
  }
] {
  const [isHovered, setHovered] = React.useState(false);
  const bind = {
    onMouseEnter: (e) => setHovered(true),
    onMouseLeave: (e) => setHovered(false)
  };
  return [isHovered, bind];
}

export function useSize<T extends Element>(
  ref: React.RefObject<T>
): { width: number; height: number } {
  const [size, setSize] = useState<{ width: number; height: number }>(() => {
    let el = ref.current;
    return { width: el ? el.clientWidth : 0, height: el ? el.clientHeight : 0 };
  });

  const [observer] = useState(
    () =>
      new ResizeObserver((entries) => {
        const rect = entries[0] && entries[0].contentRect;
        if (rect) setSize({ width: rect.width, height: rect.height });
      })
  );

  useEffect(() => {
    const el = ref.current;
    if (el) {
      observer.observe(el);
      return () => observer.unobserve(el);
    }
  }, [ref.current]);

  useEffect(() => () => observer.disconnect(), []);

  return size;
}

export function useFrame(callback: (timestamp?: number) => void, deps?: ReadonlyArray<any>): void {
  useLayoutEffect(() => {
    callback(performance.now());
    return raf(callback, true);
  }, deps);
}

export function useImage(imageKey: string): ImageModel | undefined {
  const imgs = useStore('images');
  return imgs[imageKey];
}

export function useIcon(
  iconKey: string | IShape | IEmojiIcon | IVevImage
): IShape | string | undefined | IEmojiIcon | IVevImage {
  if (!isString(iconKey)) return iconKey;
  const model = useModel();
  if (model) {
    if (model.icons) return model.icons[iconKey];
  }

  return AppState.shapes[iconKey];
}

export function useEditorState(): IEditorState {
  const model = useModel();

  const [editorState, setEditorState] = useState<IEditorState>(
    (model && AppState.editorState[model.key]) || {
      disabled: false,
      selected: false,
      rule: 'host'
    }
  );

  // Need to subscribe to store to be sure to get value if updates happens before mount
  const [unsubscribe] = useState(() =>
    store('editorState', (state) => setEditorState(state[model.key]))
  );
  useEffect(() => unsubscribe, []);

  return editorState;
}

export function useMenu(menuKey?: string): IMenu {
  const menus = useStore('menus');
  const primaryMenu = useStore('primaryMenu');
  return (menus && menus[menuKey || (primaryMenu as string)]) || { children: [], title: '' };
}

export function useModel(key?: string): IContent | undefined {
  if (!key) return useContext(ModelContext);

  const models = useStore('models');
  if (key) {
    for (let model of models) {
      if (model.key === key) return model;
    }
  }

  return;
}

function useStore<T extends keyof IAppState>(storeKey: T): IAppState[T] {
  const [value, setValue] = useState(AppState[storeKey]);
  // Need to subscribe to store to be sure to get value if updates happens before mount
  const [unsubscribe] = useState(() => store(storeKey, setValue));
  useEffect(() => unsubscribe, []);

  return value;
}

export function useScrollTop(percentage?: boolean): number {
  const value = useStore('scrollTop');
  const { scrollHeight, height } = useViewport();
  if (percentage) return Math.max(0, value / (scrollHeight - height));
  return value;
}

export function useDevice(): string {
  return useStore('device');
}

export function useViewport(): { width: number; height: number; scrollHeight: number } {
  return useStore('viewport');
}

export function useRoute(): { pageKey?: string; path?: string } {
  return useStore('route') || {};
}
