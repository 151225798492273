import React, { forwardRef, LegacyRef, useState, useEffect, useRef } from 'react';
import { Router } from '../../manager';
import { hasResolved, preloadPageHTML } from '../../core/page-resolver';

interface ILink {
  mode: 0 | 1 | 2 | 3 | 4;
}

interface IPageLink extends ILink {
  page: string;
  tweenIn?: any;
  tweenOut?: any;
  inFront?: any;
}

interface IWidgetLink extends IPageLink {
  widget: { key: string };
}

interface IPhoneLink extends ILink {
  phone: string;
}

interface IEmailLink extends ILink {
  email: string;
}

interface IExternalLink extends ILink {
  href: string;
  target: boolean;
  nofollow: boolean;
}

interface LinkProps {
  to: ILink;
  className?: string;
  style?: { [attr: string]: any };
  children: React.ReactNode;
}

export function getLinkUrl(link: ILink): string {
  if (!link) return 'https://www.vev.design';
  if (link.mode <= 1) {
    const pageKey = (link as IPageLink).page;
    let path = Router.pagePathByKey(pageKey) || '';
    // Preload page if not yet resolved
    if (!hasResolved(pageKey)) preloadPageHTML(pageKey, path);
    if (link.mode === 1 && (link as IWidgetLink).widget) {
      path += '#' + (link as IWidgetLink).widget.key;
    }
    return path;
  }
  switch (link.mode) {
    case 3:
      return 'mailto:' + (link as IEmailLink).email;
    case 4:
      return 'tel:' + (link as IPhoneLink).phone;
    default:
      return (link as IExternalLink).href;
  }
}

export function getLinkTween(link: ILink): string | false {
  if ((link.mode <= 1 && (link as IPageLink).tweenIn) || (link as IPageLink).tweenOut) {
    return JSON.stringify({
      inFront: (link as IPageLink).inFront,
      tweenIn: (link as IPageLink).tweenIn,
      tweenOut: (link as IPageLink).tweenOut
    });
  }

  return false;
}

export default forwardRef(
  ({ to, children, ...rest }: LinkProps, ref: LegacyRef<HTMLAnchorElement>) => {
    const r = useRef<HTMLAnchorElement>();
    let href: undefined | string,
      tween: string | false = false;
    if (to) {
      href = getLinkUrl(to);
      tween = getLinkTween(to);
    }
    useEffect(() => {
      if (to) r.current.href = getLinkUrl(to);
    });

    return (
      <a
        key={href}
        ref={r}
        href={href}
        data-tween={tween}
        rel={to && (to as IExternalLink).nofollow ? 'nofollow' : undefined}
        target={to && (to as IExternalLink).target ? '_blank' : undefined}
        {...rest}
      >
        {children}
      </a>
    );
  }
);
