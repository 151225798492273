import { Router, Tracking } from '../manager';
import React from 'react';
import AppState, { store, importAllWidgets, hydratePreRenderedModels } from './state';
import { onLiveEvent } from '../utils';
import Timeline from '../model/timeline';
import system from '../system';

export { AppState, Timeline, store, importAllWidgets, hydratePreRenderedModels };

export * from './hooks';
export * from '../manager';
export * from '../components';
export * from '../utils';
export const h = React.createElement;
export const s = system;

onLiveEvent('a', 'click', (e, target) => {
  const path = target.getAttribute('href') || '';
  if (!path) return;

  const isInternal = /(^(#|\/)[^\/])/.test(path);
  // If not internal link then return

  const isEmail = /^mailto/i.test(path);
  const isPhone = /^tel/i.test(path);
  let eventCategory: string;
  // if Is internal and router enabled with not ctrol or meta key click then prevent default
  // Also prevent default if no location.host ( in editor) and internal link
  if (isInternal && (!location.host || (Router.enabled && !(e.ctrlKey || e.metaKey)))) {
    e.preventDefault();
    let tween = target.dataset.tween;
    if (tween) {
      try {
        tween = JSON.parse(tween);
      } catch (e) {
        console.error('Failed to parse tween', e);
      }
    }

    Router.go(path, tween);
    eventCategory = 'Internal';
  } else if (isEmail) eventCategory = 'Email';
  else if (isPhone) eventCategory = 'Phone';
  else eventCategory = 'Outbound';

  Tracking.send('link', eventCategory + ' Link', 'click', path);
});
