import React from 'react';
import { doc, isBrowser } from '../utils';
import { Page } from '../components';
import { hydrate } from 'react-dom';

const resolvedPages: { [pageKey: string]: Promise<string> } = {};
let isPreloadDisabled = false;
export function disabledPreload() {
  isPreloadDisabled = true;
}

async function fetchHtml(pageKey: string, path: string): Promise<string> {
  if (!isBrowser) return '';
  const res = await fetch(path);
  const text = await res.text();
  return text;
  const m = text.match(/<body>((.|\n)*)<\/body>/m);
  return (m && m[0]) || '';
}

export function hasResolved(pageKey: string) {
  return pageKey in resolvedPages;
}

export function preloadPageHTML(pageKey: string, absolutePath: string): Promise<string> {
  if (isPreloadDisabled || pageKey in resolvedPages) return resolvedPages[pageKey];
  return (resolvedPages[pageKey] = fetchHtml(pageKey, absolutePath));
}

export async function resolvePage(
  pageKey: string,
  absolutePath: string
): Promise<{
  title?: string;
  page: HTMLElement;
  fixed: HTMLCollectionOf<Element> | Element[];
}> {
  const newPageTag = doc.createElement('html');
  const html = await preloadPageHTML(pageKey, absolutePath);
  newPageTag.innerHTML = html;

  // hydratePreRenderedModels(newPageTag);
  const page = newPageTag.querySelector('.__p') as HTMLElement;
  if (!page) {
    console.error(
      `Failed to find page tag in requested page ${pageKey}:${absolutePath} (probably some wrong syntax in html)`
    );
  }

  hydrate(<Page id={pageKey} />, page);
  const fixed = newPageTag.getElementsByClassName('__f');
  if (!page) throw Error('Page not found: ' + pageKey);

  const res: {
    title?: string;
    page: HTMLElement;
    fixed: HTMLCollectionOf<Element> | Element[];
  } = { page, fixed };

  const titleTag = newPageTag.getElementsByTagName('title')[0];
  if (titleTag) res.title = titleTag.innerText;

  return res;
}
