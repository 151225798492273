import React, { PureComponent } from 'react';

export default class ErrorBounds extends PureComponent {
  state: { error: false | { message: string } } = { error: false };
  static getDerivedStateFromError(error, info) {
    return { error, errorTime: Date.now() };
  }

  static getDerivedStateFromProps(nextProps, { errorTime }) {
    // If error is older than 3sec, try to unset error
    if (Date.now() - errorTime > 3000) {
      return {
        error: false
      };
    }

    return false;
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <div className="row v-center h-center __err">
          Error: <b>{error.message}</b>
        </div>
      );
    }
    return this.props.children;
  }
}
